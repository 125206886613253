/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        /* Add body classes */
        if ($('body').hasClass('page')) {
          var breadcrumbClass = $('.breadcrumb a + span').attr('class');
          $('body').addClass(breadcrumbClass);
        }

        /* Blur effect when nav is open on mobile */
        $('.navbar-toggle').click(function() {
          $('body').toggleClass('blur');
        });

        $('#menu-main-menu .dropdown').hover(function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(400);
        }, function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(400);
        });

        /* Form newsletter */
        $('#bloc_newsletter .gform_footer').appendTo('#bloc_newsletter .ginput_container');

        /* Scroll down to newsletter form */
        $('#menu-top-menu li#menu-item-15 a').click(function() {
          $('html, body').animate({
            scrollTop: $('#bloc_newsletter').offset().top
          }, 1000);
          $('#bloc_newsletter input[type=email]').focus();
          return false;
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        /* Sticky nav */
        $(window).on('scroll', function() {
          var cur_pos = $(this).scrollTop(),
              nav = $('#main-navbar-collapse');
          if (nav.length > 0) {
            var nav_pos = nav.offset().top,
                currentElementOffset = (nav_pos - cur_pos);
            if ( currentElementOffset < cur_pos - 400 ) {
              nav.addClass('sticky');
            } else {
              nav.removeClass('sticky');
            }
          }
        });

        /* Slider Actions */
        var photos = $(".slider-photos");
        var icons = $(".slider-icons");
        var titles = $(".slider-titles");
        function syncPosition() {
          titles.trigger("next.owl.carousel");
          icons.trigger("next.owl.carousel");
        }
        photos
          .owlCarousel({
            items: 1,
            nav: false,
            autoplay: true,
            dots: false,
            loop: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            mouseDrag: false
          })
          .on("changed.owl.carousel", syncPosition);
        titles.owlCarousel({
          items: 1,
          nav: false,
          autoplay: false,
          dots: false,
          loop: true,
          mouseDrag: false,
          animateOut: "fadeOutUp",
          animateIn: "fadeInUp"
        });
        icons.owlCarousel({
          items: 1,
          nav: false,
          autoplay: false,
          dots: false,
          loop: true,
          mouseDrag: false,
          animateOut: "fadeOutUp",
          animateIn: "fadeInUp"
        });

        /* Slider Chiffres */
        $('.owl-carousel-chiffres').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          dots: false,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2
            },
            992: {
              items: 3
            }
          }
        });

        /* Blocks matchHeight */
        $('#activites_actus > div').matchHeight();
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
